import React from 'react';
import axios from 'axios';
import { rollbar } from '../Rollbar.js'

export class AddressSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = { 
            addressList: null,
            selectedAddress: null,
            loading: false,
            error: false
        };

        this.handleChange = this.handleChange.bind(this);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        if (process.env.NODE_ENV === "production") {
            window.gtag('event', 'address_selected');
        }
        else {
            window.gtag('event', 'address_selected', { 'debug_mode':true });
        }
        this.props.onClick(this.state.selectedAddress);
    }

    handleChange(e) {
        const newSelectedAddress = JSON.parse(e.target.value);
        this.setState({ selectedAddress: newSelectedAddress });
    }

    async componentDidUpdate(previousProps) {
        if (previousProps.postcode !== this.props.postcode
            || previousProps.useUprn !== this.props.useUprn) {
            this.setState({ 
                addressList: [], 
                selectedAddress: null,
                error: false,
                loading: true
            });
            
            if (this.props.postcode !== '') {
                var url = "";
                if (this.props.useUprn) {
                    url = process.env.REACT_APP_THIRTEEN_TEN_API + '/availability/address-search?postcode=' + this.props.postcode;
                }
                else {
                    url = process.env.REACT_APP_THIRTEEN_TEN_API + '/availability/openreach-address-search?postcode=' + this.props.postcode;
                }
                await axios.get(url)
                    .then(response => {
                        this.setState({ addressList: response.data });
                        this.props.addressListLoaded(response.data);
                    })
                    .catch(error => {
                        rollbar.error(error);
                        this.setState({ error: true });
                    })
                    .finally(() => {
                        this.setState({ loading: false });
                    });
            }
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="fa-2x">
                    <i className="fas fa-spinner fa-pulse"></i>
                </div>
            );
        }
        else if (this.state.error) {
            return (
                <div className="block">
                    <p>
                        Sorry, an error occurred while searching for addresses.<br />Please try again.<br /><br />Alternatively, you can <a target="_blank" rel="noreferrer" href="https://1310.io/contact">send us a message</a><br />or call us on <a href="tel:03333001310">0333 300 1310</a>.
                    </p>
                </div>
            );
        }
        else if (this.state.addressList && this.state.addressList.length === 0) {
            return (
                <div className="block">
                    <p>
                        We were unable to locate any addresses using the supplied postcode.<br />Please double-check the postcode and try again.<br /><br />Alternatively, you can <a target="_blank" rel="noreferrer" href="https://1310.io/contact">send us a message</a><br />or call us on <a href="tel:03333001310">0333 300 1310</a>.
                    </p>
                </div>
            );
        }
        else if (this.state.addressList && this.state.addressList.length > 0) {
            return (
                <div className="block">
                    <div className="field">
                        <div className="control is-expanded">
                            <div className="select is-fullwidth">
                                {this.props.useUprn &&
                                    <select id="addressList" onChange={this.handleChange}
                                        onKeyPress={event => { if (event.key === 'Enter' && this.state.selectedAddress !== null) { this.handleClick() } }}
                                    >
                                        <option value={JSON.stringify(null)}>Please select your address</option>
                                        {this.state.addressList.map((addressListItem, index) =>
                                            <option key={index} value={JSON.stringify(addressListItem)}>
                                            {addressListItem.formattedStreetAddress}
                                            </option>
                                        )}
                                    </select>
                                }
                                {!this.props.useUprn &&
                                    <select id="addressList" onChange={this.handleChange}
                                        onKeyPress={event => { if (event.key === 'Enter' && this.state.selectedAddress !== null) { this.handleClick() } }}
                                    >
                                        <option value={JSON.stringify(null)}>Please select your address</option>
                                        {this.state.addressList.map((addressListItem, index) =>
                                            <option key={index} value={JSON.stringify(addressListItem)}>
                                            {addressListItem.formattedStreetAddress}
                                            </option>
                                        )}
                                    </select>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="field">
                        <div className="control">
                            <button className="button is-primary" onClick={this.handleClick} disabled={this.state.selectedAddress === null}>Select</button>
                        </div>
                    </div>
                </div>
            );
        }
        else return null;
    }
}