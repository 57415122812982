import React from 'react';
import axios from 'axios';
import { rollbar } from '../Rollbar.js'
import { ProductDetails } from '../Product/ProductDetails';
import { ExistingProductSelected } from './ExistingProductSelected';

export class UpgradeSelection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 1,
            error: false,
            loading: false,
            upgradeSummary: null,
            availabilitySummary: null,
            currentProduct: null,
            fields: {
                upgrade_id: this.props.upgradeCode,
                service_id: 0,
                market_cat: '',
                existing_tariff_id: 0,
                upgrade_tariff_id: 0,
                router_id: 0,
                local_pop_id: 0,
                location_id: 0
            }
        };

        this.handleExistingProductClick = this.handleExistingProductClick.bind(this);
        this.handleNewProductClick = this.handleNewProductClick.bind(this);
        this.handleUpgradeClick = this.handleUpgradeClick.bind(this);
        this.handleHomeClick = this.handleHomeClick.bind(this);
        this.selectExistingProduct = this.selectExistingProduct.bind(this);
        this.handleExistingProductReset = this.handleExistingProductReset.bind(this);
    }

    handleExistingProductClick(e) {
        this.selectExistingProduct(e.target);
    }

    handleExistingProductReset() {
        let fields = this.state.fields;
        fields["service_id"] = 0;
        fields["existing_tariff_id"] = 0;
        this.setState({ fields });

        this.setState({ availabilitySummary: null, currentProduct: null, step: 1 });
        this.clearSelectedProductCard();
    }

    selectExistingProduct(target) {
        this.setState({
            availabilitySummary: null,
            currentProduct: null
        }, () => {
            this.clearSelectedProductCard();

            target.closest(".card")?.classList.add("selected");

            var newExistingProductId = parseInt(target.closest(".card")?.id);
            var availabilityIndex = this.state.upgradeSummary.findIndex(x => x.currentProduct.productId === newExistingProductId);
            
            let fields = this.state.fields;
            fields["service_id"] = this.state.upgradeSummary[availabilityIndex].currentProduct.serviceId;
            fields["existing_tariff_id"] = this.state.upgradeSummary[availabilityIndex].currentProduct.productId;
            this.setState({ fields });

            this.setState({
                availabilitySummary: this.state.upgradeSummary[availabilityIndex].upgradeOptions,
                currentProduct: this.state.upgradeSummary[availabilityIndex].currentProduct
            });
        });
    }

    clearSelectedProductCard() {
        var elements = document.getElementsByClassName("existingProduct card");

        for (var i = 0; i < elements.length; i++) {
            elements[i].classList.remove("selected");
        }
    }

    handleNewProductClick(newSelectedProduct, newSelectedRouter) {
        if (newSelectedProduct === null) {
            let fields = this.state.fields;
            fields["local_pop_id"] = 0;
            fields["location_id"] = 0;
            fields["upgrade_tariff_id"] = 0;
            fields["router_id"] = 0;
            fields["market_cat"] = '';
            this.setState({ fields });
            
            this.setState({ 
                step: 1
            });
        }
        else {
            let fields = this.state.fields;
            fields["local_pop_id"] = newSelectedProduct.localPopId;
            fields["location_id"] = newSelectedProduct.locationId;
            fields["upgrade_tariff_id"] = newSelectedProduct.productId;
            fields["router_id"] = newSelectedRouter !== null ? newSelectedRouter.id : 0;
            fields["market_cat"] = this.state.availabilitySummary.find(x => x.productId === newSelectedProduct.productId).marketCat;
            this.setState({ fields });

            this.setState({
                step: 2
            });
        }
    }

    handleHomeClick() {
        window.location.href = "https://1310.io/";
    }

    async handleUpgradeClick() {
        this.setState({ loading: true });
        await axios.post(process.env.REACT_APP_THIRTEEN_TEN_API + '/confirmation/upgrade', this.state.fields)
        .then(() => {
            this.setState({step: 3});
        })
        .catch(error => {
            rollbar.error(error);
            this.setState({error: true});
        })
        .finally(() => {
            this.setState({loading: false});
        });
    }

    async componentDidMount() {
        this.setState({
            error: false,
            loading: true
        });
        await axios.get(process.env.REACT_APP_THIRTEEN_TEN_API + '/upgrade/options?id=' + this.props.upgradeCode)
            .then((response) => {
                this.setState({ 
                    upgradeSummary: response.data
                },
                function() {
                    if (this.state.upgradeSummary && this.state.upgradeSummary.length === 1) {
                        var productId = this.state.upgradeSummary[0].currentProduct.productId;
                        
                        var elements = document.getElementsByClassName("existingProduct card");

                        for (var i = 0; i < elements.length; i++) {
                            if (elements[i].id === productId.toString()) {
                                this.selectExistingProduct(elements[i]);
                            }
                        }
                    }
                });
            })
            .catch(error => { 
                rollbar.error(error);
                this.setState({ error: true });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        return (
            <div id="upgradeSelection">
                {
                    this.state.step < 3 && this.state.upgradeSummary !== null && this.state.upgradeSummary.length === 0 &&
                    <div className="block">
                        <p>
                            We were unable to locate any upgradeable services on your account.<br /><br />If you would like more information, please <a target="_blank" rel="noreferrer" href="https://1310.io/contact">send us a message</a><br />or call us on <a href="tel:03333001310">0333 300 1310</a>.
                        </p>
                </div>
                }
                {
                    this.state.error &&
                    <div className="block">
                        <p>Sorry, an error occurred while retrieving your upgrade options. Please try again later.<br /><br />Alternatively, you can <a target="_blank" rel="noreferrer" href="https://1310.io/contact">send us a message</a> or call us on <a href="tel:03333001310">0333 300 1310</a> to discuss.</p>
                    </div>
                }
                {
                    this.state.step < 3 && this.state.upgradeSummary !== null && this.state.upgradeSummary.length === 1 && this.state.currentProduct === null &&
                    <h2 className="title is-6 mt-3 mb-3">Current Service</h2>
                }
                {
                    this.state.step < 3 && this.state.upgradeSummary !== null && this.state.upgradeSummary.length > 1 && this.state.currentProduct === null &&
                    <h2 className="title is-6 mt-3 mb-3">Please select which service you would like to upgrade:</h2>
                }
                {
                    this.state.step < 3 && this.state.currentProduct !== null &&
                    <ExistingProductSelected selectedProduct={this.state.currentProduct} onClick={this.handleExistingProductReset} 
                        allowChange={this.state.upgradeSummary && this.state.upgradeSummary.length > 1} />
                }
                {
                    this.state.step < 3 && this.state.upgradeSummary !== null && this.state.currentProduct === null &&
                    this.state.upgradeSummary.map(upgradeOptions => (
                        <div key={upgradeOptions.currentProduct.productId} className="columns is-mobile is-centered is-vcentered">
                            <div className="column is-full-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd">
                                <div className="existingProduct card hover" onClick={this.handleExistingProductClick} id={upgradeOptions.currentProduct.productId}>
                                    <div className="card-header">
                                        <div className="card-header-title is-centered">
                                        <h2 className="subtitle is-5">{upgradeOptions.currentProduct.productName}</h2>
                                        </div>
                                    </div>
                                    <div className="card-content">
                                        <p>
                                            £{upgradeOptions.currentProduct.monthlyFee.toFixed(2)} per month
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
                {
                    this.state.step < 3 && this.state.availabilitySummary !== null &&
                    <ProductDetails selectedAddress={this.state.selectedAddress} onClick={this.handleNewProductClick} 
                        availabilitySummary={this.state.availabilitySummary} currentProduct={this.state.currentProduct} />
                }
                {
                    this.state.step === 2 &&
                    <div className="columns is-mobile is-centered is-vcentered mt-3">
                        <div className="column is-full-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd">
                            <div className="card selected">
                                <div className="card-header">
                                    <div className="card-header-title is-centered">
                                    <h2 className="subtitle is-5">Customer Confirmation</h2>
                                    </div>
                                </div>
                                <div className="card-content">
                                    {
                                        this.state.fields["market_cat"] === 'A' && 
                                        <p className="block">
                                            Please review your selections above and click the Upgrade button below when ready.
                                            <br /><br />
                                            Once we have reviewed your upgrade request we will contact you to confirm the date of your service upgrade.
                                        </p>
                                    }
                                    {
                                        this.state.fields["market_cat"] !== 'A' && 
                                        <p className="block">
                                            Please review your selections above and click the Upgrade button below when ready.
                                            <br /><br />
                                            Once we have reviewed your upgrade request we will contact you to confirm the date of your service upgrade.
                                        </p>
                                    }
                                    <div className="field">
                                        <div className="control">
                                            <button className="button is-primary" onClick={this.handleUpgradeClick}>Upgrade</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.step === 3 &&
                    <div className="columns is-mobile is-centered is-vcentered mt-3">
                        <div className="column is-full-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd">
                            <div className="card selected">
                                <div className="card-header">
                                    <div className="card-header-title is-centered">
                                    <h2 className="subtitle is-5">Thank You</h2>
                                    </div>
                                </div>
                                <div className="card-content">
                                    {
                                        this.state.fields["market_cat"] === 'A' && 
                                        <p className="block">
                                            Thank you for your request. We will be in touch soon to confirm the details of your upgrade.
                                        </p>
                                    }
                                    {
                                        this.state.fields["market_cat"] !== 'A' && 
                                        <p className="block">
                                            Thank you for your request. We will be in touch soon to confirm the details of your upgrade.
                                        </p>
                                    }
                                    <div className="field">
                                        <div className="control">
                                            <button className="button is-primary" onClick={this.handleHomeClick}>Home</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.loading &&
                    <div className="fa-2x">
                        <i className="fas fa-spinner fa-pulse"></i>
                    </div>
                }
            </div>
        )
    }
}