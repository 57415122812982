import React from 'react';
import axios from 'axios';
import { rollbar } from '../Rollbar.js'

export class CustomerConfirmation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: false,
            agree: false
        };

        this.handleSubmitClick = this.handleSubmitClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange() {
        this.setState({agree: !this.state.agree});
    }

    async handleSubmitClick() {
        if (process.env.NODE_ENV === "production") {
            window.gtag('event', 'customer_confirmation', {
            'service_cost_inc_vat': this.props.orderSummary.service_cost_inc_vat,
            'service_description': `${this.props.orderSummary.service_description}` });
        }
        else {
            window.gtag('event', 'customer_confirmation', { 'debug_mode':true, 
            'service_cost_inc_vat': this.props.orderSummary.service_cost_inc_vat,
            'service_description': `${this.props.orderSummary.service_description}` });
        }
        this.setState({ loading: true });

        await axios.get(process.env.REACT_APP_THIRTEEN_TEN_API + '/confirmation?redirectFlowId=' + this.props.redirectFlowId + '&invoiceDate=' + this.props.invoiceDate)
        .catch(error => {
            rollbar.error(error);
            this.setState({error: true});
        })
        .finally(() => {
            this.setState({loading: false}, function() {
                this.props.onSubmit(this.state.error);
              });
        });
    }

    render() {
        return (
            <div className='mt-5'>
                {
                    this.state.loading &&
                    <div className="fa-2x">
                        <i className="fas fa-spinner fa-pulse"></i>
                    </div>
                }
                {
                    this.state.error &&
                    <p className="title is-6 mb-2">Sorry, an error occurred while placing your order.<br /><br />Please <a target="_blank" rel="noreferrer" href="https://1310.io/contact">send us a message</a> or call us on <a href="tel:03333001310">0333 300 1310</a> to discuss.</p>
                }
                {
                    !this.state.loading && !this.state.error &&
                    <div id="customer-confirmation" className="card selected">
                        <div className="card-header">
                            <div className="card-header-title is-centered">
                                <h2 className="subtitle is-5">Order Confirmation</h2>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="field">
                                <div className="control">
                                {
                                    this.props.orderSummary.is_business === true && 
                                    <label className="checkbox">
                                        <input type="checkbox" className="mr-1" defaultChecked={this.state.agree} onChange={this.handleChange} />
                                        I confirm I have read and agree to the terms & conditions at <a target="_blank" rel="noreferrer" href="https://1310.io/terms-business">https://1310.io/terms-business</a>
                                    </label>
                                }
                                {
                                    this.props.orderSummary.is_business === false && 
                                    <label className="checkbox">
                                        <input type="checkbox" className="mr-1" defaultChecked={this.state.agree} onChange={this.handleChange} />
                                        I confirm I have read and agree to the terms & conditions at <a target="_blank" rel="noreferrer" href="https://1310.io/terms">https://1310.io/terms</a>
                                    </label>
                                }
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button className="button is-primary" disabled={!this.state.agree} onClick={this.handleSubmitClick}>Confirm Order</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}