import React from 'react';
import axios from 'axios';
import { rollbar } from '../Rollbar.js'

export class CustomerEmail extends React.Component {
    constructor(props) {
        super(props);

        this.state = { 
            email: '',
            error: false,
            loading: false,
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit() {
        this.setState({ 
            error: false,
            loading: true
        });
        await axios.get(process.env.REACT_APP_THIRTEEN_TEN_API + '/upgrade/start?email=' + this.state.email)
            .then((response) => {
                this.setState({ submitted: true });
            })
            .catch(error => { 
                rollbar.error(error);
                this.setState({ error: true });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    handleChange(e) {
        const newEmail = e.target.value;
        this.setState({ email: newEmail });
    }

    render() {
        return (
            <div className="block">
                <div className="columns is-mobile is-centered is-vcentered">
                    <div className="column is-full-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd">
                        <p className="block">Please enter the email address associated with your 1310 account</p>
                        <div className="box">
                            {
                                !this.state.submitted &&
                                <div className="field has-addons">
                                    <div className="control is-expanded">
                                        <input className="input" placeholder="Email" id="email" type="text" value={this.state.email} onChange={this.handleChange}
                                            onKeyPress={event => { if (event.key === 'Enter' && this.state.email !== '') { this.handleSubmit() } }} />
                                    </div>
                                    <div className="control">
                                        <button className="button is-primary" onClick={this.handleSubmit} disabled={this.state.email === ''}>Submit</button>
                                    </div>
                                </div>
                            }
                            {
                                this.state.submitted &&
                                <p>
                                    You will shortly receive an email containing a link to begin the upgrade process.<br/><br/>Please check your email and click on the link to proceed.
                                </p>
                            }
                            {
                                this.state.error &&
                                <p>
                                    Unfortunately, we are unable to complete the upgrade process for your address online.<br />Please <a target="_blank" rel="noreferrer" href="https://1310.io/contact">send us a message</a><br />or call us on <a href="tel:03333001310">0333 300 1310</a> to discuss upgrade options.
                                </p>
                            }
                        </div>
                        {
                            this.state.loading &&
                            <div className="fa-2x">
                                <i className="fas fa-spinner fa-pulse"></i>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}