import 'balloon-css';
import React from 'react';
import axios from 'axios';
import { rollbar } from './Rollbar.js'

export class CustomerDetails extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            fields: {
                first_name: '', 
                surname: '', 
                phone: '', 
                email: '',
                username: '',
                referral_code: this.props.referralCode != null ? this.props.referralCode : '',
                discount_code: this.props.selectedProduct?.availableDiscount?.discountCode != undefined ? this.props.selectedProduct?.availableDiscount?.discountCode : '',
                category: 'person',
                street_1: this.props.selectedAddress.formattedStreetAddress,
                city: this.props.selectedAddress.post_town,
                zip_code: this.props.selectedAddress.postcode,
                uprn: this.props.selectedAddress.uprn,
                tariff_id: this.props.selectedProduct?.productId,
                router_id: this.props.selectedRouter?.id != undefined ? this.props.selectedRouter?.id : 0,
                local_pop_id: this.props.selectedProduct?.localPopId,
                location_id: this.props.selectedProduct?.locationId
            },
            errors: {
                first_name: '',
                surname: '', 
                phone: '', 
                email: '',
                already_registered: false,
                username: '',
                referral_code: '',
                discount_code: '',
                go_cardless: false,
                generic: false,
                new_service_request: false
            },
            loading: false,
            validated: false,
            referralText: '',
            discountText: '',
            referralSummary: null,
            discountSummary: null
        };

        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleSurnameChange = this.handleSurnameChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handleReferralCodeChange = this.handleReferralCodeChange.bind(this);
        this.handleDiscountCodeChange = this.handleDiscountCodeChange.bind(this);

        this.handleSubmitClick = this.handleSubmitClick.bind(this);
        this.handleBusinessClick = this.handleBusinessClick.bind(this);
        this.handleChangeClick = this.handleChangeClick.bind(this);
        this.handleAdditionalServiceRequestClick = this.handleAdditionalServiceRequestClick.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.handleGenericError = this.handleGenericError.bind(this);
        this.handleConfirmClick = this.handleConfirmClick.bind(this);
    }

    handleChangeClick() {
        this.setState({ validated: false });
    }

    async handleAdditionalServiceRequestClick(e) {
        e.preventDefault();
        this.setState({ loading: true });
        await axios.post(process.env.REACT_APP_THIRTEEN_TEN_API + '/AdditionalService/new-request', this.state.fields)
        .then(response => {
            alert('New service request submitted. We will be in touch soon to confirm details.');
            window.location = "https://1310.io/";
        })
        .catch(error => {
            rollbar.error(error);
            let errors = this.state.errors;
            errors.new_service_request = true;
            this.setState({errors});
        })
        .finally(() => {
            this.setState({loading: false});
        });
    }

    handleBusinessClick() {
        this.props.onBusinessClick();
    }

    async handleSubmitClick() {
        if (process.env.NODE_ENV === "production") {
            window.gtag('event', 'customer_details_submitted');
        }
        else {
            window.gtag('event', 'customer_details_submitted', { 'debug_mode':true });
        }
        if (this.handleValidation()) {
            if (process.env.NODE_ENV === "production") {
                window.gtag('event', 'customer_details_validated');
            }
            else {
                window.gtag('event', 'customer_details_validated', { 'debug_mode':true });
            }
            this.setState({ loading: true });
            await axios.post(process.env.REACT_APP_THIRTEEN_TEN_API + '/customer/validate', this.state.fields)
            .then(response => {
                var monthlyDiscountText = '';

                if (response.data.monthlyDiscountGbp > 0)
                {
                    if (response.data.monthlyDiscountDuration > 0)
                    {
                        monthlyDiscountText =  "£" + response.data.monthlyDiscountGbp.toFixed(2) + " discount for the first " + (response.data.monthlyDiscountDuration > 1 ? response.data.monthlyDiscountDuration + " months\n" : "month\n");
                    }
                    else
                    {
                        monthlyDiscountText = "£" + response.data.monthlyDiscountGbp.toFixed(2) + " monthly discount\n";
                    }
                }
                
                const setupDiscountText = response.data.setupDiscountGbp > 0 ? "Up to £" + response.data.setupDiscountGbp.toFixed(2) + " setup discount\n" : '';
                const routerDiscountText = response.data.routerDiscountGbp > 0 ? "Up to £" + response.data.routerDiscountGbp.toFixed(2) + " router discount\n" : '';
                
                var minimumTermText = '';

                if (response.data.minimumTerm != null && response.data.minimumTerm > 0 && response.data.minimumTerm != this.props.selectedProduct.term)
                {
                    minimumTermText = "Minimum term: " + response.data.minimumTerm + " months";
                }

                if (monthlyDiscountText !== '' || setupDiscountText !== '' || routerDiscountText !== '' || minimumTermText !== '') {
                    const discountSummaryText = (
                        <p className='pre'>
                            {monthlyDiscountText}
                            {setupDiscountText}
                            {routerDiscountText}
                            {minimumTermText}
                        </p>
                    );
                    
                    this.setState({
                        discountSummary: discountSummaryText
                    });
                }
                else {
                    this.setState({
                        discountSummary: ''
                    });
                }

                if (response.data.creditAmountGBP > 0) {
                    const referralSummaryText = (
                        <p>£{response.data.creditAmountGBP.toFixed(2)} account credit for you and your referrer</p>
                    );
                    
                    this.setState({
                        referralSummary: referralSummaryText
                    });
                }
                else {
                    this.setState({
                        referralSummary: ''
                    });
                }

                this.setState({ validated: true }, function() {
                    document.getElementById("customerDetailsConfirmation").scrollIntoView({ block: 'end',  behavior: 'smooth' });
                });
            })
            .catch(error => {
                rollbar.error(error);
                if (error.response) {
                    var errors = error.response.data.detail?.split(',');
                    errors.forEach(error => {
                        if (error.toLowerCase().includes('email')) {
                            let errors = this.state.errors;
                            errors.email = error;
                            if (error.toLowerCase() === 'email address already registered') {
                                errors.already_registered = true;
                            }
                            this.setState({errors});
                        }
                        else if (error.toLowerCase().includes('username')) {
                            let errors = this.state.errors;
                            errors.username = error;
                            this.setState({errors});
                        }
                        else if (error.toLowerCase().includes('referral code')) {
                            let errors = this.state.errors;
                            errors.referral_code = error;
                            this.setState({errors});
                        }
                        else if (error.toLowerCase().includes('discount code')) {
                            let errors = this.state.errors;
                            errors.discount_code = error;
                            this.setState({errors});
                        }
                        else {
                            this.handleGenericError();
                        }
                    });
                }
                else {
                    this.handleGenericError();
                }
            })
            .finally(() => {
                this.setState({ loading: false });
            });
        }
    }
    
    async handleConfirmClick() {
        this.setState({ loading: true });
        await axios.post(process.env.REACT_APP_THIRTEEN_TEN_API + '/payment/registration', this.state.fields)
        .then(response => {
            if (process.env.NODE_ENV === "production") {
                window.gtag('event', 'gocardless_redirect');
            }
            else {
                window.gtag('event', 'gocardless_redirect', { 'debug_mode':true });
            }
            window.location.href = response.data.redirectUrl;
        })
        .catch(error => {
            rollbar.error(error);
            let errors = this.state.errors;
            errors.go_cardless = true;
            this.setState({errors});
        })
        .finally(() => {
            this.setState({loading: false});
        });
    }

    handleGenericError() {
        let errors = this.state.errors;
        errors.generic = true;
        this.setState({errors});
    }

    handleFirstNameChange(e) {
        const newFirstName = e.target.value;
        let fields = this.state.fields;
        fields["first_name"] = newFirstName;
        this.setState({ fields });
    }

    handleSurnameChange(e) {
        const newSurname = e.target.value;
        let fields = this.state.fields;
        fields["surname"] = newSurname;
        this.setState({ fields });
    }
    
    handlePhoneChange(e) {
        const newPhoneNumber = e.target.value;
        let fields = this.state.fields;
        fields["phone"] = newPhoneNumber;
        this.setState({ fields });
    }
    
    handleEmailChange(e) {
        const newEmailAddress = e.target.value;
        let fields = this.state.fields;
        fields["email"] = newEmailAddress;
        this.setState({ fields });
    }

    handleReferralCodeChange(e) {
        const newReferralCode = e.target.value;
        let fields = this.state.fields;
        fields["referral_code"] = newReferralCode;
        this.setState({ fields });
    }

    handleDiscountCodeChange(e) {
        const newDiscountCode = e.target.value;
        let fields = this.state.fields;
        fields["discount_code"] = newDiscountCode;
        this.setState({ fields });
    }

    handleUsernameChange(e) {
        const newUsername = e.target.value;
        let fields = this.state.fields;
        fields["username"] = newUsername;
        this.setState({ fields });
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = this.state.errors;
        let formIsValid = true;

        errors["referral_code"] = "";
        errors["discount_code"] = "";

        if(!fields["first_name"]){
           formIsValid = false;
           errors["first_name"] = "Please enter your first name";
        } else {
            errors["first_name"] = "";
        }
        
        if(!fields["surname"]){
            formIsValid = false;
            errors["surname"] = "Please enter your surname";
        } else {
            errors["surname"] = "";
        }

        if(!fields["phone"]){
            formIsValid = false;
            errors["phone"] = "Please enter your mobile number";
        } else {
            errors["phone"] = "";
        }
        
        if(!fields["username"]){
            formIsValid = false;
            errors["username"] = "Please enter a username";
        } else {
            let validUsername = fields["username"].match(/^[a-zA-Z0-9]+$/);
            if (!validUsername) {
                formIsValid = false;
                errors["username"] = "Username must contain letters and numbers only";
            } else {
                errors["username"] = "";
            }
        }

        if(fields["email"]){
            let lastAtPos = fields["email"].lastIndexOf('@');
            let lastDotPos = fields["email"].lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
                formIsValid = false;
                errors["email"] = "Email is not valid";
            } else {
                errors["email"] = "";
            }
        } else {
            formIsValid = false;
            errors["email"] = "Please enter your email address";
        }

        this.setState({errors: errors});
        return formIsValid;
    }

    async componentDidMount() {
        document.getElementById("customerDetails").scrollIntoView({ block: 'end',  behavior: 'smooth' });
    }

    render() {
        if (!this.state.validated)
        return (
            <div id="customerDetails" className="columns is-mobile is-centered is-vcentered mt-3">
                <div className="column is-full-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd">
                    <div className="block">
                        <h1 className="title is-3 mb-1">Your Details</h1>
                        <button className="button is-text has-text-link pb-3" onClick={this.handleBusinessClick}>Business customer? Click here.</button>
                        <div className="box mt-1">
                            <div className="field">
                                <div className="control is-expanded">
                                    {this.state.errors.first_name.length > 0 && <p className='has-text-danger has-text-left'>{this.state.errors.first_name}</p>}
                                    <input className={`input ${this.state.errors.first_name.length > 0 ? "is-danger" : ""}`} placeholder="First name" id="first_name" type="text" value={this.state.fields.first_name} onChange={this.handleFirstNameChange} />
                                </div>
                            </div>
                            <div className="field">
                                <div className="control is-expanded">
                                    {this.state.errors.surname.length > 0 && <p className='has-text-danger has-text-left'>{this.state.errors.surname}</p>}
                                    <input className={`input ${this.state.errors.surname.length > 0 ? "is-danger" : ""}`} placeholder="Surname" id="surname" type="text" value={this.state.fields.surname} onChange={this.handleSurnameChange} />
                                </div>
                            </div>
                            <div className="field">
                                <div className="control is-expanded">
                                    {this.state.errors.phone.length > 0 && <p className='has-text-danger has-text-left'>{this.state.errors.phone}</p>}
                                    <input className={`input ${this.state.errors.phone.length > 0 ? "is-danger" : ""}`} placeholder="Mobile number" id="phone" type="text" value={this.state.fields.phone} onChange={this.handlePhoneChange} />
                                </div>
                            </div>
                            <div className="field">
                                <div className="control is-expanded">
                                    {this.state.errors.email.length > 0 && <p className='has-text-danger has-text-left'>{this.state.errors.email}</p>}
                                    {this.state.errors.already_registered && <p className='has-text-left'><a href='#' onClick={this.handleAdditionalServiceRequestClick}>Click here to request additional service</a></p>}
                                    <input className={`input ${this.state.errors.email.length > 0 ? "is-danger" : ""}`} placeholder="Email address" id="email" type="email" value={this.state.fields.email} onChange={this.handleEmailChange} />
                                </div>
                            </div>
                            {this.state.errors.username.length > 0 && <p className='has-text-danger has-text-left'>{this.state.errors.username}</p>}
                            <div className="field">
                                <div className="control is-expanded" data-balloon-length="fit" data-balloon-pos="up" aria-label="This will be your 1310 web portal username and will also be used by your router to connect to the internet. It must contain letters and numbers only.">
                                    <input className={`input ${this.state.errors.username.length > 0 ? "is-danger" : ""}`} placeholder="Username" id="username" type="text" value={this.state.fields.username} onChange={this.handleUsernameChange}
                                        onKeyPress={event => { if (event.key === 'Enter' && this.state.fields.referral_code === '' && this.state.fields.discount_code === '') { this.handleSubmitClick() } }} />
                                </div>
                            </div>
                            {this.state.errors.referral_code.length > 0 && <p className='has-text-danger has-text-left'>{this.state.errors.referral_code}</p>}
                            <div id="referralInput" className="field">
                                <div className="control is-expanded"  data-balloon-length="fit" data-balloon-pos="up" aria-label="If you have a referral code from an existing 1310 customer, please enter it here">
                                    <input className={`input ${this.state.errors.referral_code.length > 0 ? "is-danger" : ""}`} placeholder="Referral code" id="referralCode" type="text" value={this.state.fields.referral_code} onChange={this.handleReferralCodeChange}
                                        onKeyPress={event => { if (event.key === 'Enter') { this.handleSubmitClick() } }} />
                                </div>
                            </div>
                            {this.state.referralText && <p className='mb-2 has-text-left'>{this.state.referralText}</p>}
                            {this.state.errors.discount_code.length > 0 && <p className='has-text-danger has-text-left'>{this.state.errors.discount_code}</p>}
                            <div id="discountInput" className="field">
                                <div className="control is-expanded"  data-balloon-length="fit" data-balloon-pos="up" aria-label="If you have a discount code, please enter it here">
                                    <input className={`input ${this.state.errors.discount_code.length > 0 ? "is-danger" : ""}`} placeholder="Discount code" id="discountCode" type="text" value={this.state.fields.discount_code} onChange={this.handleDiscountCodeChange}
                                        onKeyPress={event => { if (event.key === 'Enter') { this.handleSubmitClick() } }} />
                                </div>
                            </div>
                            {this.state.discountText && <div className='mb-2 has-text-left'>{this.state.discountText}</div>}
                            <div className="field">
                                <div className="control">
                                    {this.state.errors.generic && <p className='has-text-centered mb-2'>Sorry, an error occurred while validating your account details.<br />Please <a target="_blank" rel="noreferrer" href="https://1310.io/contact">send us a message</a> or call us on <a href="tel:03333001310">0333 300 1310</a> to discuss.</p>}
                                    <button className="button is-primary" onClick={this.handleSubmitClick} disabled={this.state.loading}>
                                        {this.state.loading ? <div className="fa-2x"><i className="fas fa-spinner fa-pulse"></i></div> : 'Submit'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        );
        else return (
            <div id="customerDetailsConfirmation" className="columns is-mobile is-centered is-vcentered mt-3">
                <div className="column is-full-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd">
                    <div className="card selected">
                        <div className="card-header">
                            <div className="card-header-title is-centered">
                                <h2 className="subtitle is-5">Your Details</h2>
                            </div>
                        </div>
                        <div className="card-content">
                            <dl>
                                <dt>Name:</dt>
                                <dd>{this.state.fields["first_name"]} {this.state.fields["surname"]}</dd>
                                <dt className='mt-3'>Mobile:</dt>
                                <dd>{this.state.fields["phone"]}</dd>
                                <dt className='mt-3'>Email:</dt>
                                <dd>{this.state.fields["email"]}</dd>
                                <dt className='mt-3'>Portal username:</dt>
                                <dd>{this.state.fields["username"]}</dd>
                                <dt className='mt-3'>Referral code:</dt>
                                <dd>{this.state.fields["referral_code"] !== '' ? this.state.fields["referral_code"] : 'Not supplied'}</dd>
                                {this.state.fields["referral_code"] !== '' && <dt className='mt-3'>Referral reward:</dt>}
                                {this.state.fields["referral_code"] !== '' && <dd>{this.state.referralSummary}</dd>}
                                <dt className='mt-3'>Discount code:</dt>
                                <dd>{this.state.fields["discount_code"] !== '' ? this.state.fields["discount_code"] : 'Not supplied'}</dd>
                                {this.state.fields["discount_code"] !== '' && <dt className='mt-3'>Discount summary:</dt>}
                                {this.state.fields["discount_code"] !== '' && <dd>{this.state.discountSummary}</dd>}
                            </dl>
                            <button className="button is-text has-text-link mt-3" onClick={this.handleChangeClick}>Change</button>
                        </div>
                    </div>
                    <div className="card selected mt-5">
                        <div className="card-header">
                            <div className="card-header-title is-centered">
                                <h2 className="subtitle is-5">Direct Debit Setup</h2>
                            </div>
                        </div>
                        <div className="card-content">
                            <p>You will now be redirected to our payment provider partner, GoCardless, to set up automated payments using Direct Debit.</p>
                            {this.state.errors.go_cardless && <p className='has-text-centered mb-2'>Sorry, an error occurred while setting up your direct debit.<br />Please <a target="_blank" rel="noreferrer" href="https://1310.io/contact">send us a message</a> or call us on <a href="tel:03333001310">0333 300 1310</a> to discuss.</p>}
                            <button className="button is-primary mt-3" onClick={this.handleConfirmClick}>Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}