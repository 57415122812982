import React from 'react';

export class PostcodeSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = { 
            postcode: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        if (process.env.NODE_ENV === "production") {
            window.gtag('event', 'postcode_submitted');
        }
        else {
            window.gtag('event', 'postcode_submitted', { 'debug_mode':true });
        }
        this.props.onClick(this.state.postcode);
    }

    handleChange(e) {
        const newPostcode = e.target.value;
        this.setState({ postcode: newPostcode });
    }

    async componentDidMount() {
        const queryString = require('query-string');
        const postcodeQS = queryString.parse(window.location.search).postcode;
        
        if (postcodeQS) {
            this.setState({ postcode: postcodeQS }, function() {
                this.handleClick();
            });
        }
    }

    render() {
        return (
            <div className="field has-addons">
                <div className="control is-expanded">
                    <input className="input" placeholder="Postcode" id="postcode" type="text" value={this.state.postcode} onChange={this.handleChange}
                        onKeyPress={event => { if (event.key === 'Enter' && this.state.postcode !== '') { this.handleClick() } }} />
                </div>
                <div className="control">
                    <button className="button is-primary" onClick={this.handleClick} disabled={this.state.postcode === ''}>Search</button>
                </div>
            </div>
        )
    }
}