import React from 'react';
import ProductHelper from "./ProductHelper";

export class AvailableProducts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isBusiness: false
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleBusinessClick = this.handleBusinessClick.bind(this);
    }

    handleClick(e) {
        if (process.env.NODE_ENV === "production") {
            window.gtag('event', 'product_selected');
        }
        else {
            window.gtag('event', 'product_selected', { 'debug_mode':true });
        }

        var elements = document.getElementsByClassName("product card");

        for (var i = 0; i < elements.length; i++) {
            elements[i].classList.remove("selected");
        }

        e.target.closest(".card")?.classList.add("selected");

        var newSelectedProductId = parseInt(e.target.closest(".card")?.id);

        this.props.onClick(newSelectedProductId);
    }

    handleBusinessClick() {
        this.setState({ isBusiness: !this.state.isBusiness });
    }

    render() {
        return(
            <div id="availableProducts">
                <h1 className="title is-3 mt-3 mb-3">Available Services</h1>
                <h2 className="title is-6">All prices include VAT</h2>
                {   
                    this.props.availabilitySummary.some(e => e.businessOnly === true) &&
                    <button className="button is-text has-text-link pb-3" onClick={this.handleBusinessClick}>{!this.state.isBusiness ? "Business customer? Click here to see our dedicated fibre packages." : "Click here to see our regular packages."}</button>
                }
                { !this.props.isPreorder &&
                    <div className="block" id="rag-status-msg">
                        { this.props.ragStatus.toLowerCase() === 'red' && <p id="red-rag-status-msg"><strong>There are known issues with providing service at your location that will potentially add delays to your order. You can proceed with placing an order, however we may need to discuss the order with you. If you are not happy with the delays you will be free to cancel your order.</strong></p> }
                        { this.props.ragStatus.toLowerCase() === 'amber' && <p id="amber-rag-status-msg"><strong>There are known delays at this location, delivery of your new service may not be as quick as we would usually like. We will be in touch with an expected installation date shortly after we receive your order. If this date is not acceptable you will be free to cancel your order.</strong></p> }
                    </div>
                }
                { this.props.isPreorder &&
                    <div className="block" id="preorder-msg">
                        <p><strong>We are currently accepting preorders at this location while we build out our infrastructure.</strong></p>
                        <p><strong>We encourage you to place an order now as installations will be scheduled in the order that they are received.</strong></p>
                        <p><strong>You will not be charged anything until we have contacted you to book your order in for installation.</strong></p>
                    </div>
                }
                { this.props.marketCat === 'B' && 
                    <p className="block">Good news! We are present in your local telephone exchange, which means we can provide you a great service without making use of the national wholesale networks.
                    <br /><br />
                    Your predicted speeds are shown below, though please note this is based on your neighbours experiences and your actual speeds may vary.
                    </p>
                }
                <div className="columns is-multiline is-mobile is-centered">
                    {this.props.availabilitySummary.map(product => (this.state.isBusiness == product.businessOnly ? 
                        <div key={product.productId} className="column is-full-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd">
                            <div className="product card hover" onClick={this.handleClick} id={product.productId}>
                                <div className="card-header">
                                    <div className="card-header-title is-centered">
                                    <h2 className="subtitle is-5">{product.productName}</h2>
                                    </div>
                                </div>
                                <div className="card-content product-description">
                                    { product.marketCat !== 'B' && product.productDescription && <p className="block pre">{product.productDescription}</p> }
                                    <p className="block">
                                        {product.minDownloadSpeed !== null ? ProductHelper.getFormattedDownloadRange(product) : product.maxDownloadSpeed + " Mbps download"}
                                        <br />
                                        {product.minUploadSpeed !== null ? ProductHelper.getFormattedUploadRange(product) : product.maxUploadSpeed + " Mbps upload"}
                                    </p>
                                    <p className="block">{ProductHelper.getProductTerm(product)}</p>
                                    {ProductHelper.getMonthlyFee(product)}
                                    {this.props.showSetupFee ? ProductHelper.getSetupFee(product) : ""}
                                    {ProductHelper.getOfferText(product)}
                                    <div className="field mt-5">
                                        <div className="control">
                                            <button className="button is-primary" onClick={this.handleClick}>Select</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                    ))}
                </div>
            </div>
        );
    }
}